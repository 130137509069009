import { Neutral } from 'app/theme';
import styled from 'styled-components';

export const EditField = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  width: max-content;
  padding: 0 4px;

  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const TableWrapper = styled.div`
  margin-top: 32px;

  .ant-table-thead > tr > th {
    font-weight: 600;
    color: ${Neutral[100]};
  }
`;
