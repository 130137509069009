import tj from '@mapbox/togeojson';
import { parse as csvParse } from 'csv-parse/browser/esm';
import JSZip from 'jszip';
import proj4 from 'proj4';
import shp from 'shpjs';
import * as topojson from 'topojson-client';
import { parse } from 'wellknown';
import * as XLSX from 'xlsx';
import { getExtension, getFileName } from './getGeojsonInput';
import soilCanonicalAttributesRaw from './soilCanonicalAttributes.json';

import './style.less';

interface SoilCanonicalAttribute {
  attribute: string;
  description: string;
  uom: string;
  possibleValues: string;
}

const soilCanonicalAttributes = Array.isArray(soilCanonicalAttributesRaw)
  ? soilCanonicalAttributesRaw
  : Object.values(soilCanonicalAttributesRaw);

export const fileConverter: any = {};

fileConverter.getCorrectResponse = (geojson: any, fileName: string) => {
  let response = geojson;
  if (geojson.length > 1) {
    for (const val of geojson) {
      if (!('bbox' in val) && 'type' in val && val.type === 'FeatureCollection') {
        response = val;
        break;
      }
    }
  }
  return {
    response,
    fileName: getFileName(fileName)
  };
};

fileConverter.fromKmlToGeojson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e?.target?.result || '';
    const parser = new DOMParser();
    const kml = parser.parseFromString(data.toString(), 'application/xml');
    const geojson = tj.kml(kml);
    callback(fileConverter.getCorrectResponse(geojson, file.name));
  };
  reader.readAsText(file);
};

fileConverter.fromWktToGeojson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e?.target?.result || '';
    const geojson = parse(data.toString());
    callback({
      response: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: Object.assign({}, geojson)
          }
        ]
      },
      fileName: getFileName(file.name)
    });
  };
  reader.readAsText(file);
};

fileConverter.fromTopojsonToGeojson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const str: any = e?.target?.result;
    const data = JSON.parse(str);
    const geojson = topojson.feature(data, data.objects.collection);
    callback(fileConverter.getCorrectResponse(geojson, file.name));
  };
  reader.readAsText(file);
};

// projection = 2154 => this is lambert-93
// projection = 5842 => this is america-5842
// projection = 26591 => this is monte-mario-rome-italy-26591
fileConverter.changeProjection = (geojson: any, projectionType: string) => {
  if (projectionType === '2154' || projectionType === '5842' || projectionType === '26591') {
    proj4.defs(
      'EPSG:2154',
      '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
    );
    proj4.defs(
      'EPSG:5842',
      '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs'
    );
    proj4.defs(
      'EPSG:26591',
      '+proj=tmerc +lat_0=0 +lon_0=-3.45233333333333 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +pm=rome +units=m +no_defs'
    );
    geojson.features.map((feature: any) => {
      const coordinates = feature.geometry.coordinates;
      feature.geometry.coordinates = coordinates.map((rows: any) => {
        return rows.map((row: any) => {
          return proj4(`EPSG:${projectionType}`, 'WGS84', [row[0], row[1]]);
        });
      });
      return feature;
    });
    return geojson;
  } else {
    return geojson;
  }
};

fileConverter.fromZipToGeojson = (
  file: any,
  callback: any,
  projectionType: string,
  checkInvalidExt: boolean
) => {
  const allowedExt = ['shp', 'prj', 'dbf', 'shx', 'cpg'];
  const mandatoryExt = ['shp', 'dbf', 'shx'];
  const providedExt: any = [];
  const invalidExt: any = [];
  JSZip.loadAsync(file)
    .then((zip) => {
      zip.forEach((relativePath, zipEntry) => {
        const ext = getExtension(zipEntry.name, false);
        if (ext) {
          providedExt.push(ext);
          if (allowedExt.includes(ext) === false) {
            invalidExt.push(ext);
          }
        }
      });

      const missingMandatoryExt = mandatoryExt.filter((ext) => {
        return !providedExt.includes(ext);
      });

      if (missingMandatoryExt.length > 0) {
        callback(
          new Error(`Missing following mandatory files - ${missingMandatoryExt.toString()}`)
        );
      } else if (invalidExt.length > 0 && checkInvalidExt === true) {
        callback(new Error(`Folder contains following invalid files - ${invalidExt.toString()}`));
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data: any = e?.target?.result || [];
          shp(data)
            .then((geojson: any) => {
              const correctResponse = fileConverter.getCorrectResponse(geojson, file.name);
              callback({
                response: fileConverter.changeProjection(correctResponse.response, projectionType),
                fileName: getFileName(file.name)
              });
            })
            .catch((error) => {
              callback(error);
            });
        };
        reader.readAsArrayBuffer(file);
      }
    })
    .catch((error) => {
      callback(error);
    });
};

fileConverter.convertJson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const str: any = e?.target?.result;
    const geojson = JSON.parse(str);
    callback(fileConverter.getCorrectResponse(geojson, file.name));
  };
  reader.readAsText(file);
};

fileConverter.fromGeojsonToGeojson = (file: any, callback: any) => {
  fileConverter.convertJson(file, callback);
};

fileConverter.fromJsonToGeojson = (file: any, callback: any) => {
  fileConverter.convertJson(file, callback);
};

fileConverter.fromShpToGeojson = (file: any, callback: any) => {
  fileConverter.convertJson(file, callback);
};

fileConverter.fromCsvToGeojson = (file: File, callback: (result: any) => void) => {
  const reader = new FileReader();
  reader.onload = async (e: ProgressEvent<FileReader>) => {
    const csvData = e.target?.result as string;
    if (!csvData) {
      callback(new Error('Failed to read CSV file'));
      return;
    }

    try {
      const attributeMap = createAttributeMap();

      // Detect the delimiter
      const firstLine = csvData.split('\n')[0];
      const delimiter = [',', ';', '\t'].find((d) => firstLine.includes(d)) ?? ',';

      // Split the header manually
      const headers = firstLine.split(delimiter).map((h) => h.trim());

      const parser = csvParse(csvData, {
        columns: headers,
        skip_empty_lines: true,
        delimiter: delimiter
      });

      const jsonData: any[] = [];
      for await (const record of parser) {
        jsonData.push(record);
      }

      processFileData(jsonData, file.name, callback);
    } catch (error) {
      callback(error as Error);
    }
  };

  reader.readAsText(file);
};

fileConverter.fromXlsToGeojson = (file: File, callback: (result: any) => void) => {
  const reader = new FileReader();
  reader.onload = async (e: ProgressEvent<FileReader>) => {
    const data = e.target?.result;
    if (!data) {
      callback(new Error('Failed to read Excel file'));
      return;
    }

    try {
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      processFileData(jsonData, file.name, callback);
    } catch (error) {
      callback(error as Error);
    }
  };

  reader.readAsArrayBuffer(file);
};

function createAttributeMap(): Map<string, string> {
  const attributeMap = new Map<string, string>();
  soilCanonicalAttributes.forEach((row: any) => {
    if (row.possibleValues) {
      row.possibleValues
        .toLowerCase()
        .split(', ')
        .forEach((value: string) => {
          attributeMap.set(value, row.attribute);
        });
    }
  });
  return attributeMap;
}

function processRows(jsonData: any[], attributeMap: Map<string, string>) {
  const features: GeoJSON.Feature[] = [];
  const headers = Object.keys(jsonData[0]);

  const actualLatField = headers.find((key) => ['latitude', 'lat'].includes(key.toLowerCase()));
  const actualLonField = headers.find((key) =>
    ['longitude', 'long', 'lon'].includes(key.toLowerCase())
  );

  if (!actualLatField || !actualLonField) {
    return { features, error: new Error('Latitude or longitude field not found in file') };
  }

  for (const row of jsonData) {
    const { lat, lon } = parseCoordinates(row, actualLatField, actualLonField);
    if (isNaN(lat) || isNaN(lon)) continue;

    const { properties, additionalColumnCount } = createProperties(
      headers,
      row,
      attributeMap,
      actualLatField,
      actualLonField
    );

    if (additionalColumnCount > 0) {
      features.push(createFeature(lon, lat, properties));
    } else {
      return {
        features,
        error: new Error(
          'At least one additional column is required besides latitude and longitude'
        )
      };
    }
  }

  return { features, error: null };
}

function parseCoordinates(row: any, latField: string, lonField: string) {
  const lat = parseFloat(String(row[latField]).replace(',', '.'));
  const lon = parseFloat(String(row[lonField]).replace(',', '.'));
  return { lat, lon };
}

function createProperties(
  headers: string[],
  row: any,
  attributeMap: Map<string, string>,
  latField: string,
  lonField: string
) {
  const properties: Record<string, string> = {};
  let additionalColumnCount = 0;

  for (const key of headers) {
    if (key !== latField && key !== lonField) {
      const canonicalAttribute = attributeMap.get(key.toLowerCase()) ?? key;
      properties[canonicalAttribute] = String(row[key]);
      additionalColumnCount++;
    }
  }

  return { properties, additionalColumnCount };
}

function createFeature(
  lon: number,
  lat: number,
  properties: Record<string, string>
): GeoJSON.Feature {
  return {
    type: 'Feature',
    properties,
    geometry: {
      type: 'Point',
      coordinates: [lon, lat]
    }
  };
}

function processFileData(jsonData: any[], fileName: string, callback: any) {
  if (jsonData.length === 0) {
    callback(new Error('No data found in the file'));
    return;
  }

  const attributeMap = createAttributeMap();
  const { features, error } = processRows(jsonData, attributeMap);

  if (error) {
    callback(error);
    return;
  }

  if (features.length === 0) {
    callback(new Error('No valid features found in the file'));
    return;
  }

  const geoJSON: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features
  };

  callback(fileConverter.getCorrectResponse(geoJSON, fileName));
  console.log('Conversion completed successfully.');
}

fileConverter.convertShapeFiles = (file: any, callback: any, projectionType: string) => {
  const zip = new JSZip();
  for (const val of file) {
    zip.file(val.name, val);
  }
  zip
    .generateAsync({ type: 'arraybuffer' })
    .then((content: any) => {
      shp(content)
        .then((geojson: any) => {
          callback({
            response: fileConverter.changeProjection(
              fileConverter.getCorrectResponse(geojson, file[0].name).response,
              projectionType
            ),
            fileName: getFileName(file.name)
          });
        })
        .catch((error) => {
          callback(error);
        });
    })
    .catch((error) => {
      callback(error);
    });
};

/**
 * use this function
 * @param file
 * @param callback
 * @param projectionType
 * @param checkInvalidExt
 * @param shapeFiles
 */
export const getGeojson = (
  file: any,
  callback: any,
  projectionType = 'WGS84',
  checkInvalidExt = false,
  shapeFiles = false
) => {
  if (shapeFiles) {
    fileConverter.convertShapeFiles(file, callback, projectionType);
  } else {
    let ext = getExtension(file.name, false).toLowerCase();
    ext = `${ext.charAt(0).toUpperCase()}${ext.slice(1)}`;

    if (ext === 'Xls' || ext === 'Xlsx') {
      fileConverter.fromXlsToGeojson(file, callback, projectionType, checkInvalidExt);
    } else if (fileConverter[`from${ext}ToGeojson`]) {
      fileConverter[`from${ext}ToGeojson`](file, callback, projectionType, checkInvalidExt);
    } else {
      callback(new Error('Invalid File'));
    }
  }
};
