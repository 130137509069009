import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { normalize } from 'normalizr';
import { v1 as uuidv1 } from 'uuid';

import * as turf from '@turf/turf';
import moment from 'moment';
import { AppConstants } from 'utils/app.constants';
import { DATE_FORMATS } from 'utils/dateUtils';
import { request } from '../../utils/axios';
import { getHeaders, sleep, validateUUID } from '../../utils/commonMethods';
import { URL_CONSTANTS } from '../../utils/history';
import { manageRedirection } from './../../pages/multi-region/commonFunctions';
import * as schema from './schema';
import {
  FETCH_ALL_FIELDS_BY_PROP_ID_ERROR,
  FETCH_ALL_FIELDS_BY_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING,
  FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_RESET,
  FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS,
  FETCH_FIELDS_BY_ID_ERROR,
  FETCH_FIELDS_BY_ID_LOADING,
  FETCH_FIELDS_BY_ID_SUCCESS
} from './types';

const dataError = 'data.errors';

const getHeadersWithClientStrixUi = () => {
  const _headers = getHeaders();
  _headers.common['client-id'] = 'strix-ui';
  return _headers;
};

function fetchAllFieldsByPropIdLoading() {
  return {
    type: FETCH_ALL_FIELDS_BY_PROP_ID_LOADING
  };
}

function fetchAllFieldsByPropIdError(err) {
  return {
    type: FETCH_ALL_FIELDS_BY_PROP_ID_ERROR,
    err
  };
}

function fetchAllFieldsByPropIdSuccess(fields) {
  return {
    type: FETCH_ALL_FIELDS_BY_PROP_ID_SUCCESS,
    payload: fields,
    normalizedPayload: normalize(fields, schema.FieldsSchema)
  };
}

let fieldsByPropertyIdCache = {};
let allFieldsHistoryByPropIdCache = {};
let fieldByIdCache = {};
export function clearFieldsCache() {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
}

export const fetchAllFieldsByPropId = (propId, fieldVersioningDate, skipCache = false) => {
  return (dispatch) => {
    const refDate =
      fieldVersioningDate || moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);

    const cacheKey = `${propId}-${refDate}`;

    if (fieldsByPropertyIdCache[cacheKey] && !skipCache) {
      return fieldsByPropertyIdCache[cacheKey];
    }

    fieldsByPropertyIdCache[cacheKey] = request({
      method: 'get',
      url: `/v2/properties/${propId}/fields?include_extended=true&attributes=geometry&reference_date=${refDate}`,
      headers: getHeadersWithClientStrixUi()
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(fetchAllFieldsByPropIdSuccess(res.data.content));
        return res;
      })
      .catch((error) => {
        fieldsByPropertyIdCache[cacheKey] = undefined;
        dispatch(fetchAllFieldsByPropIdError(error));
        throw error;
      });
  };
};

export const getFieldsByPropertyId = async (propId, referenceDate = null) => {
  const refDate =
    referenceDate ?? moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);

  const cacheKey = `${propId}-${refDate}`;

  if (fieldsByPropertyIdCache[cacheKey]) {
    return fieldsByPropertyIdCache[cacheKey];
  }

  try {
    fieldsByPropertyIdCache[cacheKey] = request.get(
      `/v2/properties/${propId}/fields?include_extended=true&attributes=geometry&reference_date=${refDate}`,
      {
        headers: getHeaders()
      }
    );
    return fieldsByPropertyIdCache[cacheKey];
  } catch (e) {
    fieldsByPropertyIdCache[cacheKey] = undefined;
    return {
      data: { content: [] },
      error: e
    };
  }
};

export const createFieldByPropIdPromisify = async ({
  property,
  name,
  geometry,
  declared_area,
  parent_id,
  calculated_area,
  eventDate
}) => {
  const data = {
    name,
    property_id: property.id,
    parent_region_id: parent_id || property.root_region_id,
    geometry,
    declared_area,
    calculated_area,
    event_date: eventDate
  };
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};

  return request.post(`${process.env.REACT_APP_API_URL}/v2/fields`, data, {
    headers: getHeaders()
  });
};

const getValidUUID = (id) => (validateUUID(id) ? id : uuidv1());

const getUpdatedFields = (
  newFields,
  parentId,
  operation,
  eventDate,
  isCreateFieldUsingDetectField
) => {
  function truncateCoordinates(geometry) {
    let truncatedGeometry = null;
    try {
      if (geometry) {
        truncatedGeometry = turf.truncate(geometry, { coordinates: 2 });
      }
    } catch {}
    return truncatedGeometry;
  }
  return newFields.map((field) => {
    const {
      id,
      name,
      geometry,
      declared_area,
      calculated_area,
      buffer_zone,
      max_cut_out,
      event_date,
      json_extended_attributes
    } = field;
    const fieldId = isCreateFieldUsingDetectField ? uuidv1() : getValidUUID(id); // Create new id while creating field in detect field, It should be unique in data base
    const updatedField = {
      type: 'Feature',
      id: fieldId,
      geometry: truncateCoordinates(geometry),
      event_date: field.event_date,
      properties: {
        name,
        parent_id: parentId,
        operation: operation,
        id: fieldId,
        declared_area,
        calculated_area,
        ...((operation !== 'delete' && !isEmpty(eventDate) && { event_date: eventDate }) ||
          (operation !== 'delete' && !isEmpty(event_date) && { event_date: event_date }) ||
          {})
      },
      json_extended_attributes,
      backup_detected_field_id: id
    };
    if (buffer_zone) {
      updatedField.properties.buffer_zone = buffer_zone;
    }
    if (max_cut_out) {
      updatedField.properties.max_cut_out = max_cut_out;
    }
    return updatedField;
  });
};

const addJsonExtendedAttributesOfFields = async (fields, farmId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  const sigpacFields = fields.map((field) => {
    const properties = field.properties || {};
    const isCreateFieldUsingDetectField = !!field.json_extended_attributes;
    const jsonExtendedAttributes = field.json_extended_attributes || {};
    jsonExtendedAttributes.detected_field_id = field.backup_detected_field_id;
    return {
      json_extended_attributes: jsonExtendedAttributes,
      property_id: farmId,
      parent_region_id: properties.parent_id,
      declared_area: properties.declared_area,
      name: properties.name,
      id: properties.id,
      geometry: field.geometry,
      isCreateFieldUsingDetectField
    };
  });

  const response = await Promise.all(
    sigpacFields.map((field) =>
      // eslint-disable-next-line no-use-before-define
      editFieldById({
        property: { id: field.property_id },
        parent_id: field.parent_region_id,
        name: field.name,
        declared_area: field.declared_area,
        json_extended_attributes: field.json_extended_attributes,
        fieldId: field.id,
        geometry: field.geometry,
        isCreateFieldUsingDetectField: field.isCreateFieldUsingDetectField
      })
    )
  );
  return response;
};

export const createBulkFieldsByPropId = async ({
  parentId,
  newFields,
  orgId,
  farmId,
  operation = 'create',
  fromLandingPropertyTableView = false,
  fromRegion = false,
  fromDeepAction = false,
  eventDate,
  fromManageRegions = false,
  shouldRedirectAfterSuccess = true,
  navigate,
  isCreateFieldUsingDetectField = false,
  fieldValueFromState = [],
  allFieldsDeleteClicked = false
}) => {
  try {
    fieldsByPropertyIdCache = {};
    allFieldsHistoryByPropIdCache = {};
    fieldByIdCache = {};

    const data = {
      version: 'v1',
      strict: true,
      relative_root: parentId,
      format: 'geojson',
      region_set: {
        type: 'FeatureCollection',
        features: getUpdatedFields(
          newFields,
          parentId,
          operation,
          eventDate,
          isCreateFieldUsingDetectField
        )
      }
    };

    const propertyRes = await request.post(`/v2/properties/${farmId}/fields/updates`, data, {
      headers: getHeaders()
    });
    if (isCreateFieldUsingDetectField) {
      const fieldsToUpdate = get(data, 'region_set.features');
      await addJsonExtendedAttributesOfFields(fieldsToUpdate, farmId);
    }
    if (!isEmpty(propertyRes) && propertyRes.status === 201) {
      if (propertyRes.data.status === 'REJECTED') {
        throw propertyRes.data.errors;
      }
      if (
        !fromLandingPropertyTableView &&
        !fromRegion &&
        !fromDeepAction &&
        !fromManageRegions &&
        operation !== 'delete' &&
        shouldRedirectAfterSuccess
      ) {
        if (navigate) {
          navigate(URL_CONSTANTS.ALL_FIELDS({ orgId, farmId }), {
            state: {
              newFields: newFields
            }
          });
        }
      }
      if (fromRegion && newFields.length > 0) {
        manageRedirection({
          orgId,
          farmId,
          parentId,
          newFields,
          isFildsAdded: true,
          navigate: navigate
        });
      }
      afterFieldDeleteFunc(
        operation,
        allFieldsDeleteClicked,
        fieldValueFromState,
        navigate,
        newFields,
        orgId,
        farmId
      );
    }
    sleep(1000);
    return propertyRes;
  } catch (ex) {
    const response = ex.response;
    if (fromDeepAction) {
      throw response.data;
    } else if (isEmpty(get(response, dataError))) {
      throw ex;
    }
    throw get(response, dataError);
  }
};

function afterFieldDeleteFunc(
  operation,
  allFieldsDeleteClicked,
  fieldValueFromState,
  navigate,
  newFields,
  orgId,
  farmId
) {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  if (operation === 'delete' && allFieldsDeleteClicked && fieldValueFromState.length > 0) {
    const newFieldRes = fieldValueFromState?.filter((stateField) => {
      const result = newFields?.find((selectedField) => selectedField.id === stateField.id);
      return !result;
    });
    navigate(URL_CONSTANTS.ALL_FIELDS({ orgId, farmId }), {
      state: {
        newFields: newFieldRes
      },
      replace: true
    });
  }
}

export const editFieldById = async ({
  property,
  name,
  geometry,
  declared_area,
  fieldId,
  parent_id,
  calculated_area,
  event_date,
  json_extended_attributes,
  isCreateFieldUsingDetectField = false
}) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  const data = {
    name,
    property_id: property.id,
    parent_region_id: parent_id || property.root_region_id,
    geometry,
    declared_area,
    id: fieldId,
    calculated_area,
    event_date
  };
  let includeExtended = false;
  if (json_extended_attributes) {
    includeExtended = true;
    data.json_extended_attributes = json_extended_attributes;
  }

  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};

  const headers = getHeaders();
  if (isCreateFieldUsingDetectField) {
    headers.common['client-id'] = AppConstants.SIGPAC_FIELD_CLIENT_ID;
  }
  const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);
  return request.put(
    `/v2/fields/${fieldId}?reference_date=${currentDate}${
      includeExtended ? '&include_extended=true' : ''
    }`,
    data,
    {
      headers
    }
  );
};

export const getFieldById = async (id) => {
  if (fieldByIdCache[id]) return fieldByIdCache[id];
  const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);

  fieldByIdCache[id] = await request.get(
    `/v2/fields/${id}?include_extended=true&attributes=geometry&reference_date=${currentDate}`,
    {
      headers: getHeadersWithClientStrixUi()
    }
  );
  return fieldByIdCache[id];
};

export const fetchFieldById = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_FIELDS_BY_ID_LOADING });
    try {
      const res = await getFieldById(id);
      if (res.error) {
        throw res.error;
      }
      dispatch({ type: FETCH_FIELDS_BY_ID_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: FETCH_FIELDS_BY_ID_ERROR, error: error });
    }
  };
};

export const deleteFieldByFieldId = async (fieldId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  const response = await request.delete(`/v2/fields/${fieldId}`, {
    headers: getHeaders()
  });
  return response;
};

export const deleteFieldVersionByFieldIdAndVersionId = (fieldId, versionId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return request.delete(`/v2/fields/${fieldId}/history/${versionId}`, {
    headers: getHeaders()
  });
};

export const deleteFieldVersionByFieldIdAndEventDate = (fieldId, date) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return request.delete(`/v2/fields/${fieldId}/event-date/${date}`, {
    headers: getHeaders()
  });
};

/**
 * to delete version of a field using version id
 * @param {*} fieldId
 * @param {*} versionId
 * @returns
 */
export const deleteFieldVersionById = (fieldId, versionId) => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return request.delete(`v2/fields/${fieldId}/history/${versionId}`, {
    headers: getHeaders()
  });
};
/**
 * to fetch fields of multiple properties using ids
 * @param {*} propIds
 */
export const getFieldsOfMultipleProperties = async (propIds, setIsLoadingFields) => {
  const fieldsByProperty = {};
  if (setIsLoadingFields) setIsLoadingFields(true);

  const chunkSize = 100;
  const promises = [];
  const currentDate = moment(new Date()).format(DATE_FORMATS.YYYY_DASH_MM_DASH_DD_NUMBER);
  for (let i = 0; i < propIds.length; i += chunkSize) {
    const chunk = propIds.slice(i, i + chunkSize);
    promises.push(
      (async () => {
        try {
          return await request.post(
            `/v2/properties/ids/fields?reference_date=${currentDate}`,
            { ids: chunk },
            { headers: getHeaders() }
          );
        } catch {}
      })()
    );
  }
  await Promise.all(promises).then((responses) => {
    responses.forEach((response) => {
      response?.data.content.forEach((field) => {
        const currentFields = fieldsByProperty[field.property_id] ?? [];
        fieldsByProperty[field.property_id] = [...currentFields, field];
      });
    });
  });

  if (setIsLoadingFields) setIsLoadingFields(false);
  return fieldsByProperty;
};

function fetchAllFieldsHistoryByPropIdLoading() {
  return {
    type: FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_LOADING
  };
}

function fetchAllFieldsHistoryByPropIdError(err) {
  return {
    type: FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_ERROR,
    err
  };
}

function fetchAllFieldsHistoryByPropIdSuccess(fields) {
  return {
    type: FETCH_ALL_FIELDS_HISTORY_BY_PROP_ID_SUCCESS,
    payload: fields
  };
}

export const fetchAllFieldsHistoryByPropId = (propId) => {
  return (dispatch) => {
    if (allFieldsHistoryByPropIdCache[propId]) {
      dispatch(
        fetchAllFieldsHistoryByPropIdSuccess(allFieldsHistoryByPropIdCache[propId].data.fields)
      );
      return allFieldsHistoryByPropIdCache[propId];
    }
    dispatch(fetchAllFieldsHistoryByPropIdLoading());
    request({
      method: 'get',
      url: `/v2/properties/${propId}/fields/history?attributes=geometry`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(fetchAllFieldsHistoryByPropIdSuccess(res.data.fields));
        allFieldsHistoryByPropIdCache[propId] = res;
        return res;
      })
      .catch((error) => {
        allFieldsHistoryByPropIdCache[propId] = undefined;
        dispatch(fetchAllFieldsHistoryByPropIdError(error));
        return Promise.reject(error);
      });
    return allFieldsHistoryByPropIdCache[propId];
  };
};

function fetchFieldHistoryDetailsByIdLoading() {
  return {
    type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_LOADING
  };
}

function fetchFieldHistoryDetailsByIdError(err) {
  return {
    type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_ERROR,
    err
  };
}

function fetchFieldHistoryDetailsByIdSuccess(fieldHistory) {
  return {
    type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_SUCCESS,
    payload: fieldHistory
  };
}

/**
 * get field history details by field id
 * @param {*} propId
 * @returns
 */
export const fetchFieldHistoryDetailsById = (fieldId) => {
  return (dispatch) => {
    dispatch(fetchFieldHistoryDetailsByIdLoading());
    return request({
      method: 'get',
      url: `/v2/fields/${fieldId}/history`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(fetchFieldHistoryDetailsByIdSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(fetchFieldHistoryDetailsByIdError(error?.response?.data || error));
        return Promise.reject(error);
      });
  };
};

/**
 * get field history details by field id
 * @param {*} propId
 * @returns
 */
export const resetFieldHistoryDetailsById = () => {
  fieldsByPropertyIdCache = {};
  allFieldsHistoryByPropIdCache = {};
  fieldByIdCache = {};
  return (dispatch) => {
    dispatch({
      type: FETCH_FIELD_HISTORY_DETAILS_BY_ID_RESET
    });
    return true;
  };
};
