import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { StyledModal } from 'app/common';
import { ArrowRightIcon } from 'app/common/icons/arrow-right.icon';
import { ArchiveIcon } from 'app/fields/archiving/archive.icon';
import {
  ArchivingModalsEnum,
  OverlappingFieldViewModel,
  useArchivingInternal
} from 'app/fields/archiving/archiving-internal.provider';
import { Blue, Neutral } from 'app/theme';
import { Trans, useTranslation } from 'react-i18next';
import { history, URL_CONSTANTS } from 'utils/history';
import * as CommonStyled from '../../archiving.style';
import { getFormattedDateToOverlapFields } from '../../archiving.util';
import * as Styled from './unarchive-error.style';

export function UnarchiveError() {
  // We can't use useParams here as we're not inside the router provider
  const pathname = window.location.pathname;
  const orgId = pathname.split('/')[3];
  const farmId = pathname.split('/')[5];
  const { t } = useTranslation();

  const {
    selectedFieldsIds,
    overlappingFields,
    modals: { [ArchivingModalsEnum.UNARCHIVE_ERROR]: open },
    triggerModal
  } = useArchivingInternal();

  const selectedFieldId = selectedFieldsIds[0];

  const onClose = () => {
    triggerModal(ArchivingModalsEnum.UNARCHIVE_ERROR);
  };
  const currentField = overlappingFields.find((field) => field.isCurrentField);
  const overlappingFieldsData = overlappingFields.filter((field) => !field.isCurrentField);

  const columns = [
    {
      title: t('archiving.notifications.unarchive_error_table.archieved'),
      dataIndex: 'archivedField-field-name',
      key: 'archivedField-field-name',
      render: () => (
        <CommonStyled.FieldInfo>
          <span>{currentField?.name}</span>
          <CommonStyled.FieldArea>
            {currentField?.area && <span>{currentField.area} ha</span>}
          </CommonStyled.FieldArea>
        </CommonStyled.FieldInfo>
      )
    },
    {
      title: t('archiving.notifications.unarchive_error_table.current'),
      dataIndex: 'current-field-name',
      key: 'current-field-name',
      render: (_: unknown, record: OverlappingFieldViewModel) => (
        <CommonStyled.FieldInfo>
          <span>{record.name}</span>
          <CommonStyled.FieldArea>
            {record.area !== undefined && record.area !== null && <span>{record.area} ha</span>}
          </CommonStyled.FieldArea>
        </CommonStyled.FieldInfo>
      )
    },
    {
      title: t('archiving.notifications.unarchive_error_table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_: unknown, record: OverlappingFieldViewModel) => {
        const isSelected = record.id === selectedFieldId;
        return (
          <Styled.Tag
            style={{
              backgroundColor: isSelected ? Neutral[10] : Blue[20],
              color: isSelected ? Neutral[70] : Blue[70]
            }}
          >
            {isSelected && <ArchiveIcon fill={Neutral[70]} />}
            <span>
              {t(
                isSelected
                  ? 'archiving.notifications.unarchive_error.archived_tag'
                  : 'archiving.notifications.unarchive_error.active_tag'
              )}
            </span>
          </Styled.Tag>
        );
      }
    },
    {
      title: t('archiving.notifications.unarchive_error_table.overlapping_period'),
      dataIndex: 'overlapping-period',
      key: 'overlapping-period',
      render: (_: unknown, record: OverlappingFieldViewModel) => (
        <Styled.Wrapper>
          <ExclamationCircleOutlined />{' '}
          <span>
            {getFormattedDateToOverlapFields(record.overlapPeriod.since, 'MM-DD-YYYY', t)}{' '}
          </span>
          <ArrowRightIcon size={12} />{' '}
          <span>
            {getFormattedDateToOverlapFields(record.overlapPeriod.until, 'MM-DD-YYYY', t)}
          </span>
        </Styled.Wrapper>
      )
    },
    {
      title: t('archiving.notifications.unarchive_error_table.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_: unknown, record: OverlappingFieldViewModel) => {
        const isSelected = record.id === selectedFieldId;

        if (isSelected) return null;

        const label = t('archiving.notifications.unarchive_error_table.edit_field');

        return (
          <Styled.EditField
            href={URL_CONSTANTS.EDIT_FIELD_DRAW({ orgId, farmId, fieldId: record.id })}
            role="button"
            aria-label={label}
            onClick={(ev) => {
              ev.preventDefault();

              onClose();
              history.push(URL_CONSTANTS.EDIT_FIELD_DRAW({ orgId, farmId, fieldId: record.id }));
            }}
          >
            <EditOutlined />
            <span>{label}</span>
          </Styled.EditField>
        );
      }
    }
  ];

  const fieldName = overlappingFields?.[0]?.name;

  return (
    <StyledModal
      open={open}
      showClose={true}
      error={true}
      width={950}
      onClose={onClose}
      title={t('archiving.notifications.unarchive_error.title')}
      ok={{
        onClick: onClose,
        text: t('common.button.cancel')
      }}
    >
      <Styled.TableWrapper>
        <Trans key={'archiving.notifications.unarchive_error.description'} values={{ fieldName }}>
          The <b>{fieldName}</b> that you are trying to unarchive conflicts with active fields. Edit
          the geometry of the active fields to unarchive the <b>{fieldName}</b>.
        </Trans>

        <Table
          dataSource={overlappingFieldsData}
          rowKey={'id'}
          columns={columns}
          style={{ margin: '16px 0' }}
          pagination={false}
          bordered
        />
      </Styled.TableWrapper>
    </StyledModal>
  );
}
