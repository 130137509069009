import { createContext, PropsWithChildren, useContext } from 'react';

export enum ArchivingModalsEnum {
  CONFIRM_ARCHIVE = 'CONFIRM_ARCHIVE',
  ARCHIVE_ERROR = 'ARCHIVE_ERROR',
  CONFIRM_UNARCHIVE = 'CONFIRM_UNARCHIVE',
  UNARCHIVE_ERROR = 'UNARCHIVE_ERROR'
}
export interface OverlappingFieldViewModel {
  id: string;
  name: string;
  area: number;
  overlapPeriod: { since: string; until: string };
  isCurrentField: boolean;
}
interface ArchivingInternalState {
  modals: {
    [key in keyof typeof ArchivingModalsEnum]: boolean;
  };
  loading: boolean;
  fieldsInActiveSeason: { id: string; name: string; season: string }[];
  overlappingFields: OverlappingFieldViewModel[];
  selectedFieldsIds: string[];
}

interface ArchivingInternalActions {
  triggerModal: (modal: ArchivingModalsEnum) => void;
  goToSeasons: () => void;
  archiveFields: () => void;
  unarchiveField: () => void;
  resetState: () => void;
}

type ArchivingInternal = ArchivingInternalState & ArchivingInternalActions;
const ArchivingInternalContext = createContext<ArchivingInternal>({} as ArchivingInternal);

export function ArchivingInternalProvider({
  children,
  value
}: PropsWithChildren<{ value: ArchivingInternal }>) {
  return (
    <ArchivingInternalContext.Provider value={value}>{children}</ArchivingInternalContext.Provider>
  );
}

export function useArchivingInternal() {
  return useContext(ArchivingInternalContext);
}

export const initialInternalState: ArchivingInternalState = {
  modals: {
    [ArchivingModalsEnum.CONFIRM_ARCHIVE]: false,
    [ArchivingModalsEnum.ARCHIVE_ERROR]: false,
    [ArchivingModalsEnum.CONFIRM_UNARCHIVE]: false,
    [ArchivingModalsEnum.UNARCHIVE_ERROR]: false
  },
  loading: false,
  fieldsInActiveSeason: [],
  overlappingFields: [],
  selectedFieldsIds: []
};
