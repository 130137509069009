export function ArrowRightIcon({
  fill = '#696F88',
  size = 16
}: Readonly<{
  fill?: string;
  size?: number;
}>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 3.33301L9.06016 4.27301L12.1135 7.33301H1.3335V8.66634H12.1135L9.0535 11.7263L10.0002 12.6663L14.6668 7.99967L10.0002 3.33301Z"
        fill={fill}
      />
    </svg>
  );
}
