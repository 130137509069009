import { initCWElements } from '@cw-elements/config';
import { initMfes } from '@cw-elements/mfe-setup/init';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { isLocal } from 'app.constant';
import { ArchivingProvider } from 'app/fields/archiving';
import { LimitCreationProvider } from 'app/fields/limit-creation';
import { MapProvider } from 'components/maps/map-context/map-context';
import 'cw-elements/lib/styles/index.less';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { SENTRY_CONFIG, SENTRY_KEY } from 'sentry.config';
import 'services/async-requests/AsyncRequests';
import { initAnalytics } from 'utils/analytics/analytics';
import { request } from 'utils/axios';
import { getLocale } from 'utils/getDatePickerLocale';
import { AppLazy } from './app';
import { AppLoader } from './components/appLoader';
import { ZendeskWidget } from './components/common/zendeskWidget';
import './components/maps/mapInit';
import './i18n';
import './icomoon-v1.0/style.css';
import './index.less';
import { store } from './redux/reducer/store';
import { cleanNewUserSession, getAccessToken } from './utils/commonMethods';

if (!isLocal && SENTRY_KEY) {
  Sentry.init(SENTRY_CONFIG);
}

if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_SEGMENT_KEY) {
    initAnalytics(process.env.REACT_APP_SEGMENT_KEY);
  }
}

cleanNewUserSession(); // if new login on accounts portal, clear session on main app

const initClients = () => {
  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }

  initCWElements({
    environment: process.env.REACT_APP_CW_ELEMENTS_ENVIRONMENT,
    axiosInstance: request,
    token: tokens?.access_token,
    hostAppId: process.env.REACT_APP_BASE_REF_ID
  });
};

initClients();
const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

(async function loadAppLoader() {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHY_DARKLY_ID ?? ''
  });

  await initMfes({
    fertilizers: `${process.env.REACT_APP_CW_ELEMENTS_MFES}/fertilizers`,
    'generic-items': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/generic-items`,
    'seeds-variety': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/seeds-variety`,
    'quota-mgt': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/quota-mgt`,
    'building-management': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/building-management`,
    'crop-protection': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/crop-protection`,
    'inputs-mgt': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/inputs-mgt`,
    'entities-hall': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/entities-hall`,
    'topbar-navigation': `${process.env.REACT_APP_CW_ELEMENTS_MFES}/topbar-navigation`
  });

  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();
  if (!isRedirecting) {
    root.render(
      <Provider store={store}>
        <ConfigProvider locale={getLocale()}>
          <QueryClientProvider client={queryClient}>
            <LDProvider>
              <LimitCreationProvider>
                <ArchivingProvider>
                  <MapProvider>
                    <AppLazy />
                  </MapProvider>
                </ArchivingProvider>
              </LimitCreationProvider>
            </LDProvider>
            <ZendeskWidget />
          </QueryClientProvider>
        </ConfigProvider>
      </Provider>
    );
  }
})();
