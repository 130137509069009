import i18next from 'i18next';

/**
 *
 * @param {*} code -> error code recieved from api
 * @param {*} fallback -> fallbck language
 * @returns
 */
export const getErrorMessageByCode = (code, fallback = 'en') => {
  let messageCode = i18next.t(code) || '';
  if (messageCode === code) {
    if (i18next.t(`global.api_error.${code}`) !== `global.api_error.${code}`) {
      // if translation for error code exists in global.api_error
      messageCode = i18next.t(`global.api_error.${code}`);
    } else if (i18next.t(code, { lng: fallback }) !== code) {
      // search translation in fallback language
      messageCode = i18next.t(code, { lng: fallback });
    } else if (
      i18next.t(`global.api_error.${code}`, { lng: fallback }) !== `global.api_error.${code}`
    ) {
      // search translation in fallback language
      messageCode = i18next.t(`global.api_error.${code}`, { lng: fallback });
    }
  }
  if (messageCode.includes('returned an object instead of string.')) {
    return i18next.t(`global.api_error.${code}.heading`);
  } else {
    return messageCode || i18next.t('commom.error.something_went_wrong');
  }
};
