import JSZip from 'jszip';
import { useEffect, useState } from 'react';

import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Empty, List, Select, Tooltip, Upload } from 'antd';
import { ConfirmPopup } from './ConfirmPopup';
import ProjFileMissingWarning from './report-problem.icon';
import UploadFileEmpty from './upload-file-empty.icon';

import './style.less';

const { Dragger } = Upload;
let tempFileList: any = [];
const NON_SHP_EXT = ['.json', '.geojson', '.kml', '.zip', '.topojson', '.wkt'];
const SHP_EXT = ['.shp', '.prj', '.shx', '.dbf', '.cpg'];
const MAND_SHP_EXT = ['.shp', '.prj', '.shx', '.dbf'];
const ACCEPT_EXT = NON_SHP_EXT.concat(SHP_EXT);

const iff = (condition: any, trueRet: any, falseRet: any = '') => {
  if (condition === true) {
    if (typeof trueRet === 'function') {
      return trueRet();
    }
    return trueRet;
  } else {
    if (typeof falseRet === 'function') {
      return falseRet();
    }
    return falseRet;
  }
};

export const getFileName = (fileFullName = '') => {
  const arr = fileFullName.split('.');
  return arr[0];
};

export const getExtension = (fileFullName = '', dot = true) => {
  const arr = fileFullName.split('.');
  const ext = `${arr[arr.length - 1].toLowerCase()}`;
  if (dot === false) {
    return ext;
  }
  return `.${ext}`;
};

const { Option } = Select;
const ProjectionDD = ({
  filename,
  setProjectionType,
  projectionType,
  defaultProjection,
  labels
}: any) => {
  const onChange = (value: any) => {
    const projection = [];
    projection[filename] = value;
    setProjectionType(Object.assign({}, projectionType, projection));
  };
  const defaultValue =
    projectionType && filename in projectionType ? projectionType[filename] : defaultProjection;
  return (
    <Select
      dropdownClassName="select"
      defaultValue={defaultValue}
      onChange={onChange}
      dropdownStyle={{
        zIndex: 999999999
      }}
    >
      <Option key="5842" value="5842">
        {labels.AMERICA_5842}
      </Option>
      <Option key="2154" value="2154">
        {labels.LAMBERT_93}
      </Option>
      <Option key="26591" value="26591">
        {labels.MONTE_MARIO_26591}
      </Option>
      <Option key="WGS84" value="WGS84">
        {labels.GCS_WGS_1984}
      </Option>
    </Select>
  );
};

const DeleteConfirmPopup = ({
  fileList,
  file,
  setFileList,
  hideDeleteConfirmPopup,
  deleteIcon,
  labels
}: any) => {
  const onConfirmDelete = () => {
    const files = fileList.filter((f: any) => {
      return !file.find((c: any) => {
        return c.name === f.name;
      });
    });
    setFileList(files);
  };
  return (
    <div className="filelist-icon-container">
      {iff(
        hideDeleteConfirmPopup,
        <Tooltip title={labels.Delete_file}>
          <div className="delete-icon" onClick={onConfirmDelete}>
            {deleteIcon ? deleteIcon : <DeleteOutlined />}
          </div>
        </Tooltip>,
        <ConfirmPopup onConfirm={onConfirmDelete}>
          <Tooltip title={labels.Delete_file}>
            <div className="delete-icon">{deleteIcon ? deleteIcon : <DeleteOutlined />}</div>
          </Tooltip>
        </ConfirmPopup>
      )}
    </div>
  );
};

const validateFile = (files: any, file: any) => {
  let fileListArray = [...files];
  let isDuplicate = false;
  fileListArray = fileListArray.map((f) =>
    iff(
      f.name === file.name,
      () => {
        isDuplicate = true;
        return file;
      },
      f
    )
  );
  if (!isDuplicate) {
    fileListArray.push(file);
  }
  return fileListArray;
};

const GetMissingPrjFileWarningContent = ({ providedExt, labels }: any) => {
  if (!providedExt.includes('.prj')) {
    return (
      <div className="filelist-icon-container">
        <Tooltip title={labels.proj_file_missing_warning}>
          <div className="warning_icon">
            <ProjFileMissingWarning />
          </div>
        </Tooltip>
      </div>
    );
  } else {
    return null;
  }
};

const GetMissingPrjFileWarningWithPrjDropDown = ({
  file,
  labels,
  validationCheck,
  projectionType,
  setProjectionType,
  defaultProjection,
  i
}: any) => {
  const [providedExt, setProvidedExt] = useState<any>([]);

  useEffect(() => {
    const temp: any = [];
    if (getExtension(file[0].name) === '.zip') {
      JSZip.loadAsync(file[0])
        .then((zip) => {
          zip.forEach((relativePath, zipEntry) => {
            temp.push(getExtension(zipEntry.name));
          });
          setProvidedExt(temp);
        })
        .catch((error) => {
          if (error) {
            setProvidedExt(['.prj']);
          }
        });
    } else if (file.find((f: any) => SHP_EXT.includes(getExtension(f.name)))) {
      for (const val of file) {
        temp.push(getExtension(val.name));
      }
      setProvidedExt(temp);
    } else {
      setProvidedExt(['.prj']); // for other files dont display warning
    }
  }, [file]);

  return (
    <>
      <GetMissingPrjFileWarningContent labels={labels} providedExt={providedExt} />
      <div className="projection-dd-container">
        {validationCheck[i] &&
          (getExtension(file[0].name) === '.zip' ||
            file.find((f: any) => SHP_EXT.includes(getExtension(f.name)))) &&
          !providedExt.includes('.prj') && (
            <ProjectionDD
              projectionType={projectionType}
              setProjectionType={setProjectionType}
              defaultProjection={defaultProjection}
              filename={getFileName(file[0].name)}
              labels={labels}
            />
          )}
      </div>
    </>
  );
};

const RenderItem = (props: any) => {
  const {
    newFields,
    i,
    fieldIndex,
    validationCheck,
    labels,
    fileList,
    setFileList,
    deleteIcon,
    hideDeleteConfirmPopup
  } = props;
  const file = newFields[i];
  return (
    <List.Item key={fieldIndex} style={{ background: iff(fieldIndex % 2 !== 0, '#F5F8FA') }}>
      <List.Item.Meta
        avatar={
          <div
            style={{
              height: '12px',
              width: '12px',
              backgroundColor: iff(
                ACCEPT_EXT.indexOf(getExtension(file[0] && file[0].name)) < 0 ||
                  !validationCheck[i],
                '#EB4B4B',
                '#19A04B'
              ),
              borderRadius: '50%'
            }}
          />
        }
        title={iff(ACCEPT_EXT.indexOf(getExtension(i)) < 0, getFileName(i), i)}
        description={
          <>
            <div className="file-ext">
              {file.map((f: any, index: any) => (
                <>
                  <span key={index} style={{ marginRight: '6px' }}>
                    {getExtension(f.name)}
                  </span>
                  {iff(
                    ACCEPT_EXT.indexOf(getExtension(f.name)) < 0,
                    <div style={{ color: '#EB4B4B', fontSize: '12px' }}>
                      {labels.Invalid_file_format}
                    </div>,
                    <>
                      {iff(
                        file.length - 1 === index,
                        <div>
                          {!validationCheck[i] && (
                            <div className="shapefileset-warn">
                              {`${
                                labels.Uploaded_file_must_be_either_of
                              } ${NON_SHP_EXT.toString()} or a shapefile set (at least ${MAND_SHP_EXT.toString()})`}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              ))}
            </div>
          </>
        }
      />
      <GetMissingPrjFileWarningWithPrjDropDown file={file} labels={labels} {...props} />
      <DeleteConfirmPopup
        fileList={fileList}
        file={file}
        setFileList={setFileList}
        labels={labels}
        deleteIcon={deleteIcon}
        hideDeleteConfirmPopup={hideDeleteConfirmPopup}
      />
    </List.Item>
  );
};

export const GeojsonInput = (props: any) => {
  const { fileList, setFileList, validationCheck, labels, newFields } = props;
  useEffect(() => {
    tempFileList = fileList;
  }, [fileList]);

  const uploadProps = {
    multiple: true,
    accept: ACCEPT_EXT.toString(),
    showUploadList: false,
    name: 'file',
    beforeUpload: (file: any) => {
      let fileListArray = [...tempFileList];
      fileListArray = validateFile(fileListArray, file);
      tempFileList = fileListArray;
      setFileList(tempFileList);
      return false;
    },
    fileList: tempFileList
  };

  const customRequest = (params: { file: any; onSuccess?: any }) => {
    setTimeout(() => {
      params.onSuccess(labels.ok);
    }, 0);
  };

  const RenderList = (i: any, fieldIndex: any) => {
    return (
      <RenderItem
        i={i}
        key={i}
        fieldIndex={fieldIndex}
        validationCheck={validationCheck}
        setFileList={setFileList}
        fileList={tempFileList}
        {...props}
      />
    );
  };

  return (
    <>
      <div className="syt-dragger">
        <Dragger {...uploadProps} customRequest={customRequest} id="uploadDragger">
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ color: '#0071CD' }} />
          </p>
          <p className="ant-upload-text dragging-message">
            {labels.Click_or_drag_file_to_this_area_to_upload}
          </p>
          <p className="supported-files-name">{`${labels.Supports}: SHAPEFILE, JSON, GEOJSON, KML, TOPOJSON, WKT`}</p>
        </Dragger>
      </div>
      <div className="filelist-title">{labels.Files}</div>
      <div id="files-container">
        <List
          dataSource={Object.keys(newFields)}
          split={false}
          locale={{
            emptyText: <Empty image={<UploadFileEmpty />} description={labels.No_files} />
          }}
          renderItem={RenderList}
        />
      </div>
    </>
  );
};
